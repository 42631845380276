import Cookies from 'universal-cookie'

const generateDeviceId = () => {
  return Math.random().toString(36).substring(2)
}

export const generateGrowthBookDeviceId = (cookies: Cookies) => {
  let mcDeviceId = cookies.get('mc_growthbook_device_id')

  if (!mcDeviceId) {
    mcDeviceId = generateDeviceId()
    cookies.set('mc_growthbook_device_id', mcDeviceId)
  }

  return mcDeviceId
}
